<template>
  <div class="legacy-report-button">
    <template v-if="isSeller">
      <v-chip
        color="primary"
        class="text-uppercase px-5"
        @click="startReportProcess()"
      >
        Relatório
      </v-chip>
    </template>
  
    <template v-else>
      <Button @click="startReportProcess()">
        <strong>
          Gerar relatório
        </strong>
      </Button>
    </template>
  </div>
</template>

<script>

import { mapActions, mapGetters, mapState } from 'vuex';

import Button from '@/components/base/Button';

export default {
  name: 'LegacyReportButton',

  components: {
    Button,
  },

  props: {
    isSeller: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      report: null,
      reportID: null,
    };
  },

  computed: {
    ...mapState({
      lastSearch (state, getters) {
        return getters[`${this.$route.name}/lastSearch`];
      },
    }),

    ...mapGetters('summary', ['summary']),
  },

  methods: {
    ...mapActions('report', [
      'generateReport',
      'setReport',
    ]),

    async startReportProcess () {
      await this.getReport();
      await this.openReport();
    },

    async getReport () {
      const data = new Date();

      this.report = {
        url: this.$route.path,
        data: Intl.DateTimeFormat('pt-BR').format(data),
      };

      await this.setReport(this.report);
    
      this.reportID = await this.generateReport({
        lastSearch: this.lastSearch,
        summary: this.summary,
        route: this.$route.name,
      });
    },

    async openReport () {
      Object.assign(document.createElement('a'), {
        target: '_blank',
        href: `/report/${this.reportID}`,
      }).click();
      this.report = null;
      await this.setReport(this.report);
    },
  },
};

</script>