<script setup>

import GoogleTagManager from '@/components/google/GoogleTagManager.vue';
import FilterAreaMobile from '@/components/mobile/leadSearch/FilterArea.vue';
import ButtonBarMobile from '@/components/mobile/leadSearch/ButtonBar.vue';
import SearchResult from '@/views/shared/SearchResult.vue';
import PropertyFiltersMobile from '@/components/mobile/leadSearch/PropertyFilters.vue';
import LocationFilterMobile from '@/components/mobile/leadSearch/LocationFilter.vue';
import LocationSelectorMobile from '@/components/mobile/leadSearch/LocationSelector.vue'

</script>

<template>
  <div class="p-4 m-0">
    <FilterAreaMobile
      id="mobile-filter-area"
      :hidden="showResults || error"
      class="h-full w-full"
    >
      <LocationSelectorMobile
        slot="location-selector"
        class="w-full"
      />
      <LocationFilterMobile
        slot="filters"
        class="w-full"
        @update:region="region = $event"
      />
      <PropertyFiltersMobile
        slot="additional-filters"
        v-model="attributesFilter"
        class="w-full"
      />
      <ButtonBarMobile
        slot="button-area"
        class="relative w-full"
        :on-confirm="debouncedSearch"
        :on-clean="clearLeadFilters"
        :disabled="buttonDisabled"
        :is-loading="isLoading"
        :has-data="hasData && showResults"
      />
    </FilterAreaMobile>
    <div
      v-show="showResults || error"
      class="h-screen flex flex-col"
    >
      <SearchResult
        slot="search-results"
        class="flex h-screen"
        :loading="loading"
        :search-summary="searchSummary"
        :search-results="result && Object.values(result).filter(item => !!item.addressLine1)"
        @reload-search="debouncedSearch()"
        @back-to-search="hideResults()"
      />
    </div>
    <GoogleTagManager :track-event="gtmEvent" />
  </div>
</template>

<script>

import debounce from 'lodash.debounce';
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import { FILTER_MODE, PROPERTY_TYPE } from '@/common/constants';
import selectContentGTM from '@/utils/selectContentGTM';

export default {
  name: 'LeadSearch',
  beforeRouteLeave (to, from, next) {
    this.clearProperty();
    this.clearSearch();
    this.clearFilters();
    this.resetMapZoomLevel();
    this.setFilterMode(FILTER_MODE.RADIUS);
    this.setUseMapFilter(true);
    next();
  },
  data () {
    return {
      PROPERTY_TYPE,
      attributesFilter: null,
      searchSummary: null,
      showResults: false,
      gtmEvent: null,
      searchLoading: false,
      summaryLoading: false,
    };
  },
  computed: {
    ...mapGetters('map', ['currentCity']),
    ...mapState('core', ['expandedSidebar']),
    ...mapState({
      autocompleteData (state, getters) {
        return getters[`${this.$route.name}/autocompleteData`]; 
      },
      result (state, getters) {
        return getters[`${this.$route.name}/result`]; 
      },
      filter (state, getters) {
        return getters[`${this.$route.name}/filter`]; 
      },
      error (state, getters) {
        return getters[`${this.$route.name}/error`]; 
      },
      isLoading (state, getters) {
        return getters[`${this.$route.name}/isLoading`]; 
      },
      filterMode (state, getters) {
        return getters[`${this.$route.name}/filterMode`]; 
      },
    }),
    isMobile () {
      return this.$route.path.includes('mobile')
    },
    hasData () {
      return !!(this.result && Object.values(this.result).length);
    },
    priceMaxIsLower () {
      if (!this.attributesFilter) return false;

      if (this.attributesFilter.priceMin && this.attributesFilter.priceMax) {
        return this.attributesFilter.priceMax < this.attributesFilter.priceMin;
      }

      return false;
    },
    priceNotFulfilled () {
      if (!this.attributesFilter) return false;

      return !(!!this.attributesFilter.priceMin || !!this.attributesFilter.priceMax);
    },
    areaNotFulfilled () {
      const filter = this.attributesFilter;

      if (!filter) return false;

      return !!(
        +filter.areaRange[1] === 0
        && +filter.areaRange[0] === 0
      );
    },
    buttonDisabled () {
      const notFilterAndResult = !this.filter && !this.result;
      const notContainsAreaOrPrice = this.areaNotFulfilled && this.priceNotFulfilled;

      return notFilterAndResult || this.priceMaxIsLower || notContainsAreaOrPrice;
    },
    loading () {
      return this.isLoading || this.searchLoading || this.summaryLoading;
    },
  },
  created () {
    this.debouncedSearch = debounce(this.doSearch.bind(this), 200);
    this.$nextTick(() => {
      this.clearSearch();
      this.clearFilters();
      this.resetMapZoomLevel();
      this.setFilterMode(FILTER_MODE.RADIUS);
      this.setUseMapFilter(true);
    });

    this.$eventBus.$on('leadFlagCallback', () => {
      this.$nextTick(() => {
        this.getSearchSummary();
      });
    });
  },
  methods: {
    ...mapActions('summary', [
      'fetchSummary',
      'setRegion',
    ]),
    ...mapActions({
      clearProperty: 'property/clearProperty',
      resetMapZoomLevel: 'map/resetMapZoomLevel',
      setUseMapFilter: 'core/setUseMapFilter',
      toggleExpandSidebar: 'core/toggleExpandSidebar',
      toggleCollapseSidebar: 'core/toggleCollapseSidebar',
      search (dispatch, payload) {
        return dispatch(`${this.$route.name}/search`, payload); 
      },
      clearSearch (dispatch, payload) {
        return dispatch(`${this.$route.name}/clearSearch`, payload); 
      },
      clearFilters (dispatch, payload) {
        return dispatch(`${this.$route.name}/clearFilters`, payload); 
      },
    }),
    ...mapMutations({
      setFilter (commit, payload) {
        return commit(`${this.$route.name}/SET_FILTER`, payload); 
      },
      setFilterMode (commit, payload) {
        return commit(`${this.$route.name}/SET_FILTER_MODE`, payload); 
      },
      setError (commit, payload) {
        return commit(`${this.$route.name}/SET_ERROR`, payload); 
      },
    }),
    hideResults () {
      this.showResults = false;
      this.searchSummary = null;
      this.clearSearch();
    },
    clearLeadFilters () {
      this.clearFilters();
      this.attributesFilter = null;
    },
    async getSearchSummary () {
      try {
        this.summaryLoading = true;

        this.searchSummary = await this.fetchSummary({
          attributesFilter: this.attributesFilter,
        });

        if (this.searchSummary) {
          this.gtmEvent = {
            event: 'view_result',
            has_result: +!!this.searchSummary.condominiumCount,
          };
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.summaryLoading = false;
      }
    },
    searchTerm () {
      const searchElementByFilterMode = {
        area: '',
        radius: document.querySelector('.pac-target-input') && document.querySelector('.pac-target-input').value,
        region: document.querySelector('#regionName') && document.querySelector('#regionName').value,
      };

      return searchElementByFilterMode[this.filterMode];
    },
    async doSearch () {
      try {
        this.searchLoading = true;

        if (this.expandedSidebar) this.toggleExpandSidebar();

        this.gtmEvent = selectContentGTM({
          attributesFilter: this.attributesFilter,
          routeName: this.$route.name,
          searchTerm: this.searchTerm(),
          currentCity: this.currentCity,
          filterMode: this.filterMode,
          address: this.autocompleteData,
        });

        if (this.filterMode === 'region') {
          this.setRegion(this.searchTerm());
        }

        await this.search({
          attributesFilter: this.attributesFilter,
        });

        console.log(this.result)

        if (!this.error) {
          this.showResults = true;
          if(Object.keys(this.result).length) {
            this.getSearchSummary();
            this.$router.push('/mobile/condominium/list');
          }
        }
      } catch (error) {
        console.error(error);
        this.showResults = false;
        this.setError(error);
      } finally {
        this.searchLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#mobile-filter-area {
  ::v-deep input.pac-target-input {
    font-size: 16px !important;
  }
  ::v-deep div.search-input input {
    font-size: 16px !important;
  }
  ::v-deep input#regionName {
    font-size: 16px !important;
  }
}
</style>
