<template>
  <div
    v-if="visibleRoute"
    id="map-wrapper"
    :class="{
      'map-has-active-item': highlightedItem,
      'map-is-drawing': desenhoControl,
    }"
  >
    <MapDrawPanel
      :is-draw-finished="isDrawFinished"
      :is-drawable="isDrawable"
      :is-clearable="isClearable"
      :is-result-page="isResultPage"
      @draw="drawControl"
      @clear="clearMap"
    />

    <div
      v-if="pins.length || sellerCondo"
      class="map-pin-legend"
    >
      <div>
        <img
          src="@/assets/images/map-pin-blue.svg"
          width="18px"
        >
        <span>
          Outras imobiliárias
        </span>
      </div>
      
      <div>
        <img
          src="@/assets/images/map-pin-green.svg"
          width="18px"
        >
        <span>
          Ofertas BNSIR
        </span>
      </div>

      <div>
        <img
          src="@/assets/images/map-pin-red.svg"
          width="18px"
        >
        <span>
          Sem ofertas
        </span>
      </div>
    </div>

    <l-editable-map
      ref="map"
      :center="MAP_OPTIONS.DEFAULT_CENTER"
      :options="MAP_OPTIONS.mapOptions"
      :editable="!pins.length"
      :zoom="zoomLevel"
      :max-zoom="MAP_OPTIONS.DEFAULT_MAX_ZOOM_LEVEL"
      :min-zoom="MAP_OPTIONS.DEFAULT_ZOOM_LEVEL"
      style="height: 100%; width: 100%; z-index: 3;"
      @update:center="onMapCenterChange"
      @update:zoom="onMapZoomLevelChange"
      @click="addMarkerOnClick"
    >
      <l-tile-layer :url="url" />

      <l-circle
        v-if="circleCenter && sellerCondo"
        color="#6B8CEF"
        fill-color="#6B8CEF"
        :fill-opacity="0.2"
        :options="MAP_OPTIONS.circleOptions"
        :lat-lng="circleCenter"
        :radius="circleRadius"
      />

      <template
        v-if="freedrawingCler &&
          useMapFilter &&
          filterMode === FILTER_MODE.AREA &&
          desenhoControl"
      >
        <div
          v-if="showDrawSuccessTooltip"
          ref="drawSuccessTooltip"
          class="draw-success-tooltip"
        >
          <Typography
            tag="div"
            variant="body-medium-secondary"
            color="brand-dark"
            align="center"
          >
            Desenho aplicado com sucesso! Prossiga com os filtros à esquerda.
          </Typography>
        </div>

        <l-free-drawing
          ref="freeDraw"
          @update:polygons="onFreeDrawChanged"
        />
      </template>
      

      <l-geo-json
        v-if="useMapFilter && filterMode === FILTER_MODE.REGION && regionGeoJSON"
        ref="region"
        :geojson="regionGeoJSON"
      />

      <template
        v-if="clickMap
          && useMapFilter
          && lastCircleCenter
          && circleCenter
          && filterMode === FILTER_MODE.RADIUS"
      >
        <l-editable-circle
          ref="circle"
          color="#6B8CEF"
          fill-color="#6B8CEF"
          :fill-opacity="0.2"
          :editable="!pins.length"
          :draggable="!pins.length"
          :options="MAP_OPTIONS.circleOptions"
          :lat-lng="Array.from(circleCenter)"
          :radius="MAP_OPTIONS.DEFAULT_RADIUS"
          @editable:vertex:drag="onCircleMarkerUpdated"
        />

        <l-marker
          ref="circleMarker"
          :visible="!pins.length"
          :lat-lng="circleCenter"
        >
          <l-icon
            :icon-url="require('@/assets/images/map-pin-default.svg')"
            :icon-size="[
              iconSize.width,
              iconSize.height
            ]"
          />
        </l-marker>
      </template>

      <l-marker
        v-if="sellerCondo && sellerCondo.geolocation && !pins.length"
        ref="circleMarker"
        :lat-lng="sellerCondo.geolocation"
      >
        <l-icon
          :icon-url="require('@/assets/images/map-pin-green.svg')"
          :icon-size="[
            iconSize.width,
            iconSize.height
          ]"
        />
      </l-marker>

      <l-marker-cluster
        v-if="pins.length"
        ref="cluster"
        :options="MAP_OPTIONS.clusterOptions"
        @clusterclick="onClusterClick"
      >
        <l-marker
          v-for="({ addressId, geolocation, offerType, offersCount, valueSqrtMeter }, index) in pins"
          :key="index"
          :lat-lng="geolocation"
          :options="{ riseOnHover: true, addressId, index }"
          @ready="onMarkerReady"
        >
          <l-icon
            :class-name="
              `icon ${
                highlightedItem && highlightedItem.addressId === addressId
                  ? 'active'
                  : highlightedItem
                    ? 'inactive'
                    : ''
              }`
            "
            :icon-size="[
              iconSize.width * 3,
              iconSize.height,
            ]"
          >
            <span>
              <img
                :src="require(`@/assets/images/map-pin-${offerType ? getOfferTypeColor(offerType) : !offersCount ? 'red' : 'blue'}.svg`)"
                :width="iconSize.width"
                :height="iconSize.height"
                class="mx-auto"
              >
            </span>

            <l-tooltip
              v-if="pins && valueSqrtMeter && valueSqrtMeter !== '-'"
              class-name="tooltip"
              :options="tooltipOptions"
            >
              <div
                :class="{
                  'pin-tooltip': true,
                  [`is-${offerType ? getOfferTypeColor(offerType) : !offersCount ? 'red' : 'blue'}`]: true,
                }"
              >
                {{ valueSqrtMeter }}/m²
              </div>
            </l-tooltip>
          </l-icon>

          <l-popup>
            <PropertyItem
              v-if="pins"
              class="property__popup"
              :property="pins[index]"
              :allow-highlighting="false"
              @click="navigateToProperty(pins[index])"
            />
          </l-popup>
        </l-marker>
      </l-marker-cluster>
    </l-editable-map>

    <div v-show="false">
      {{ sidebarIsCollapsed }}
      {{ pathChange }}
    </div>

    <GoogleTagManager :track-event="gtmEvent" />
  </div>
</template>

<script>
import { gmapApi } from 'vue2-google-maps';
import { Marker } from 'leaflet';
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex';
/* eslint-disable import/order */
import { EditableMap } from 'vue2-leaflet-editable';
import Editable from '../mixins/editable';
import Vue2LeafletFreeDraw from '@/components/map/FreeDrawingLayer.vue';
import EditableCircle from '@/components/map/DraggableCircleMarker.vue';
import {
  LCircle,
  LGeoJson,
  LIcon,
  LMarker,
  LPopup,
  LTileLayer,
  LTooltip,
} from 'vue2-leaflet';
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster';
import { FILTER_MODE, MAP_OPTIONS, CLEARABLE_ROUTES } from '../common/constants';
import DistanceGrid from '@/components/map/DistanceGrid';
import PropertyItem from '@/components/property/PropertyItem.vue';

import MapDrawPanel from '@/components/MapDrawPanel';
import viewProductGTM from '../utils/viewProductGTM';
import GoogleTagManager from '../components/google/GoogleTagManager.vue';

import Typography from '@/components/base/Typography';

window.L.DistanceGrid = DistanceGrid;
window.L.Marker = Marker;
window.L.Marker.include({
  clusterHide () {
    const backup = this.options.opacity;

    this.setOpacity(0);
    this.options.opacity = backup;

    return this;
  },
  clusterShow () {
    return this.setOpacity(this.options.opacity);
  },
});

/* eslint-enable import/order */
export default {
  name: 'Map',
  components: {
    'l-editable-map': EditableMap,
    'l-editable-circle': EditableCircle,
    'l-marker-cluster': Vue2LeafletMarkerCluster,
    'l-free-drawing': Vue2LeafletFreeDraw,
    LTileLayer,
    LMarker,
    LCircle,
    LGeoJson,
    LPopup,
    LIcon,
    LTooltip,
    PropertyItem,
    GoogleTagManager,
    MapDrawPanel,
    Typography,
  },
  mixins: [Editable],
  data () {
    return {
      markers: [],
      geocoder: null,
      currentPropertyId: 0,
      url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}',
      FILTER_MODE,
      MAP_OPTIONS,
      clickMap: false,
      freedrawingCler: false,
      circleRadius: MAP_OPTIONS.DEFAULT_RADIUS,
      circleCenter: null,
      lastCircleCenter: null,
      areaPolygon: null,
      iconSize: {
        width: 37,
        height: 46,
      },
      circleMarkerInitialized: false,
      tooltipOptions: {
        permanent: true,
        direction: 'top',
      },
      desenhoControl: false,
      showDrawSuccessTooltip: false,
      sellerCondo: null,
      gtmEvent: null,
    };
  },
  computed: {
    ...mapGetters('map', [
      'pins',
      'center',
      'zoomLevel',
      'highlightedItem',
    ]),
    ...mapGetters('flag', ['flagged']),
    ...mapState('core', [
      'useMapFilter',
      'collapsedSidebar',
    ]),
    ...mapState('summary', ['summary']),
    ...mapState({
      filterMode (state, getters) {
        return getters[`${this.$route.name}/filterMode`];
      },
      filter (state, getters) {
        return getters[`${this.$route.name}/filter`];
      },
      error (state, getters) {
        return getters[`${this.$route.name}/error`];
      },
      result (state, getters) {
        return getters[`${this.$route.name}/result`];
      },
    }),
    google: gmapApi,
    sidebarIsCollapsed () {
      this.runCollapsedSidebarCheck();
      this.updateMap();
    },
    pathChange () {
      this.updateMap();

      return this.runPathCheck();
    },
    regionGeoJSON () {
      if (!(this.filter && this.filterMode === FILTER_MODE.REGION)) return null;

      const { coordinates } = this.filter;

      return {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            geometry: {
              type: 'Polygon',
              coordinates: coordinates || [],
            },
          },
        ],
      };
    },
    isSeller () {
      return this.$route.fullPath === '/search/seller';
    },
    visibleRoute () {
      return this.$route.name !== 'login';
    },
    routeIsClearable () {
      return CLEARABLE_ROUTES.some(route => route === this.$route.name);
    },
    isDrawable () {
      const conditions = [
        this.validPage(),
        !this.pins.length,
        this.filterMode === FILTER_MODE.AREA,
      ];

      return conditions.every(el => el);
    },
    isClearable () {
      const conditions = [
        this.validPage(),
        this.filterMode === FILTER_MODE.AREA,
      ];

      return conditions.every(el => el);
    },
    isDrawFinished () {
      return Boolean(this.areaPolygon);
    },
    isResultPage () {
      return Boolean(this.pins.length);
    },
  },

  watch: {

    isDrawFinished (isDrawFinished) {
    
      if (isDrawFinished) {

        this.showDrawSuccessTooltip = true;

        this.$nextTick(() => {

          const sidebar = document.querySelector('.sidebar');

          if (sidebar) {

            sidebar.addEventListener('click', this.sidebarClickListener);

            const polygon = document.querySelector('.leaflet-polygon.leaflet-interactive');
            const polygonRect = polygon.getBoundingClientRect();

            const tooltip = this.$refs.drawSuccessTooltip;
            const tooltipRect = tooltip.getBoundingClientRect();

            if (tooltip) {

              const tooltipPosition = {
                top: polygonRect.top - tooltipRect.height,
                left: polygonRect.left - (tooltipRect.width / 2) + (polygonRect.width / 2),
              };

              tooltip.style.top = `${tooltipPosition.top}px`;
              tooltip.style.left = `${tooltipPosition.left}px`;
            }
          }
        });
      }
    },

    highlightedItem (item) {
      if (item) {
        const { addressId, geolocation } = item;

        if (geolocation) {
          this.centerMapToOffset(
            geolocation,
            this.MAP_OPTIONS.DEFAULT_MAX_ZOOM_LEVEL,
          );
          setTimeout(() => {
            this.markers.forEach(marker => {
              marker.closePopup();

              if (addressId === marker.options.addressId) {
                marker.openTooltip();
              } else {
                marker.closeTooltip();
              }
            });
          }, 450);
        }
      } else {
        setTimeout(() => {
          if (this.highlightedItem) return;

          this.markers.forEach(marker => {
            marker.openTooltip();
            marker.closePopup();
          });
        }, 100);
      }
    },

    circleCenter (value, current) {
      if (!value) return;

      if (!this.useMapFilter) return;

      if (!this.equalsLatLng(value, current)) {
        try {
          const [
            lat,
            lng,
          ] = value;

          this.lastCircleCenter = [
            lat,
            lng,
          ];
          this.updateFilter({
            position: [
              lat,
              lng,
            ],
            radius: this.circleRadius,
          });
        } catch (error) {}
      }
    },

    zoomLevel (value) {
      if (this.$refs.map) {
        this.$refs.map.mapObject.setZoom(value);

        if (value === FILTER_MODE.RADIUS) {
          const { position } = this.filter;

          if (!position) return;

          this.centerMapToOffset(position);
        }
      }
    },

    circleRadius (value) {
      this.circleRadius = parseInt(this.circleRadius, 10);

      if (!this.useMapFilter) return;

      this.updateFilter({
        position: this.circleCenter,
        radius: value || MAP_OPTIONS.DEFAULT_RADIUS,
      });
    },

    areaPolygon (value) {
      if (this.result
        && this.areaPolygon) {
    
        this.searchByDraw();
        this.clearSearch();
        this.clearMarkers();
        this.setLoading(true);
      }

      if (!this.useMapFilter) return;

      this.updateFilter(value && value.geometry);
    },

    filterMode (value) {
      if (this.filterMode === FILTER_MODE.RADIUS) {
        this.freedrawingCler = false;
      }

      this.clearMap();

      if (!this.useMapFilter) return;

      this.currentPropertyId = 0;

      if (this.filter && value === FILTER_MODE.RADIUS) {
        const { position } = this.filter;

        if (!position) return;

        this.centerMapToOffset(position);
      }

      this.freedrawingCler = true;
    },

    async filter (value, current) {
  
      if (!this.useMapFilter) return;

      if (!value) {
        this.circleCenter = null;

        return;
      }

      await this.$forceUpdate();

      const { point, position } = value;

      switch (this.filterMode) {
      case FILTER_MODE.RADIUS:
        if (
          value
            && current
            && this.equalsLatLng(value.position, current.position)
        ) {
          return;
        }

        if (!position) return;

        this.circleCenter = position;
        this.updateCircle(this.circleCenter);
        break;
      case FILTER_MODE.REGION:
        if (point && this.$refs.map) {
          this.$refs.map.fitBounds(this.$refs.region.mapObject.getBounds());
        }
      }
    },

    center (newValue) {
      this.centerMapToOffset(
        newValue,
        this.MAP_OPTIONS.DEFAULT_ZOOM_LEVEL,
      );
    },
  },

  created () {
    this.$router.afterEach((to, from) => {
      if (to.path !== from.path) {
        this.clearMap();
      }
    });
  },

  mounted () {

    if (this.$refs.map) {

      L.control
        .zoom({
          position: 'bottomright',
        })
        .addTo(this.$refs.map.mapObject);
    }

    this.$eventBus.$on('condoSelected', condo => {
      let info;

      if (condo) {
        info = condo.info;
      }

      this.currentPropertyId = null;
      this.sellerCondo = null;
      this.circleCenter = null;

      if (condo) {
        if (info && info.geolocation) {
          this.currentPropertyId = info.addressId;
          this.sellerCondo = info;
          this.circleCenter = info.geolocation;
          this.centerMapToOffset(
            info.geolocation,
            this.MAP_OPTIONS.DEFAULT_MAX_ZOOM_LEVEL - 1,
          );
        }
      }
    });
  },

  methods: {

    ...mapActions('map', ['setGeocoderResults']),

    sidebarClickListener (event) {

      const { target } = event;

      if (target) {

        if (target.tagName === 'INPUT'
          || target.tagName === 'BUTTON'
          || target.tagName === 'SPAN') {

          this.$nextTick(() => {

            this.showDrawSuccessTooltip = false;
          
            const sidebar = document.querySelector('.sidebar');

            if (sidebar) {
              sidebar.removeEventListener('click', this.sidebarClickListener);
            }
          });
        }
      }
    },

    getOfferTypeColor (offerType) {
      if (!offerType.has_offers) {
        return 'red';
      } else if (offerType.has_offers && !offerType.is_own_real_estate) {
        return 'blue';
      } else if (offerType.has_offers && offerType.is_own_real_estate) {
        return 'green';
      }

      return 'unknown';
    },

    updateCluster () {
      if (this.$refs.cluster) {
        this.$refs.cluster.mapObject.clearLayers(this.markers);
        this.$refs.cluster.mapObject.addLayers(this.markers);
      }
    },

    onMarkerReady (newMarker) {
      this.markers.push(newMarker);
    },

    runPathCheck () {
      return this.$route.name;
    },

    runCollapsedSidebarCheck () {
      return this.collapsedSidebar;
    },

    updateMap () {
      if (this.$refs.map) this.$refs.map.mapObject.invalidateSize();
    },

    ...mapActions({
      fetchProperty: 'property/fetchProperty',
      clearSearch (dispatch, payload) {
        return dispatch(`${this.$route.name}/clearSearch`, payload); 
      },
      clearFilters (dispatch, payload) {
        return dispatch(`${this.$route.name}/clearFilters`, payload);
      },
      searchByDraw (dispatch, payload) {
        return dispatch(`${this.$route.name}/searchByDraw`, payload);
      },
    }),

    ...mapMutations({
      setMapCenter: 'map/SET_MAP_CENTER',
      setMapBoundaries: 'map/SET_MAP_BOUNDARIES',
      setZoomLevel: 'map/SET_MAP_ZOOM_LEVEL',
      setFilter (commit, payload) {
        return commit(`${this.$route.name}/SET_FILTER`, payload);
      },
      setLoading (commit, payload) {
        return commit(`${this.$route.name}/SET_LOADING`, payload);
      },
    }),

    validPage () {
      if (
        (this.$route.fullPath === '/search/rent'
          || this.$route.fullPath === '/search/buyer'
          || this.$route.fullPath === '/search/lead')
        && (this.filterMode === 'radius' || this.filterMode === 'area')
      ) {
        return true;
      }

      if (this.isSeller) {
        return false;
      }

      return false;
    },

    clearMarkers () {
      this.markers = [];

      if (this.$refs.map) {
        this.$refs.map.setZoom(this.MAP_OPTIONS.DEFAULT_ZOOM_LEVEL);
      }

      this.updateCluster();
    },

    clearMap (drawingButtonClick = false) {
      this.desenhoControl = false;
      this.clickMap = false;
      this.circleCenter = null;
      this.lastCircleCenter = null;
      this.areaPolygon = null;
      this.sellerCondo = null;
      this.circleRadius = MAP_OPTIONS.DEFAULT_RADIUS;
      this.initialMarker = false;

      if (this.routeIsClearable) {
        this.clearFilters();
      }

      if (!drawingButtonClick) {
        if(!this.$route.name === 'personData' && !this.$route.name === 'avm'){
          this.clearSearch();
        }
        this.clearMarkers();
      }
      
      this.freedrawingCler = false;

      if (this.filterMode === 'area') {
        setTimeout(() => {
          this.freedrawingCler = true;
        }, 100);
      }
    },

    updateCircle (position) {
      if (
        this.$refs.circle
        && this.$refs.circle.mapObject
        && this.$refs.circleMarker
        && this.$refs.circleMarker.mapObject
      ) {
        try {
          this.$refs.circle.mapObject.off('editable:vertex:drag');
          this.$refs.circle.mapObject.disableEdit();
          this.$refs.circle.mapObject.setLatLng(position);
          this.$refs.circle.mapObject.enableEdit();
          this.$refs.circle.mapObject.on(
            'editable:vertex:drag',
            this.onCircleMarkerUpdated.bind(this),
          );
        } catch (error) { }
      }

      this.centerMapToOffset(position);
    },

    centerMapToOffset (latlng, zoom) {
      
      const zoomLevel = zoom || this.zoomLevel;

      if (this.$refs.map && this.$refs.map.mapObject) {
        if (this.sellerCondo) {
          const { x, y } = this.$refs.map.mapObject.latLngToContainerPoint(
            latlng,
          );
          const point = this.$refs.map.mapObject.containerPointToLatLng([
            x,
            y,
          ]);

          this.$refs.map.mapObject.flyTo(point, zoomLevel);
        } else {
          this.$refs.map.setZoom(zoomLevel);
          this.$refs.map.setCenter(latlng);
        }
      }
    },

    updateFilter (filter) {

      if (!this.useMapFilter) return;

      this.clickMap = true;

      this.setFilter(filter);
    },

    addMarkerOnClick (event) {
      this.$refs.map.mapObject.invalidateSize();

      if (this.pins && this.pins.length) return;

      if (this.filterMode === FILTER_MODE.RADIUS) {
        if (event && event.latlng) {
          const { latlng: geolocation } = event;
          const { lat, lng } = geolocation || {};

          if (lat && lng) {
            this.circleCenter = [
              lat,
              lng,
            ];

            if (this.google) {
              if (!this.geocoder) {
                this.geocoder = new this.google.maps.Geocoder();
              }

              this.geocoder
                .geocode({ location: { lat, lng } })
                .catch(console.log)
                .then(this.setGeocoderResults);
            }
          }
        }
      }

      this.clickMap = true;
    },

    onMapCenterChange () {
      
      if (this.$refs.map
        && this.$refs.map.mapObject) {
        
        this.$refs.map.mapObject.invalidateSize();
      
        this.setMapBoundaries(this.$refs.map.mapObject.getBounds());
        this.setZoomLevel(this.$refs.map.mapObject.getZoom());

        if (this.$refs.circle 
          && this.$refs.circle.mapObject) {
        
          this.onCircleMarkerUpdated();
        }
      }
    },

    onMapZoomLevelChange () {

      if (this.$refs.map
        && this.$refs.map.mapObject) {
        
        this.setMapBoundaries(this.$refs.map.mapObject.getBounds());
        this.setZoomLevel(this.$refs.map.mapObject.getZoom());

        if (this.$refs.circle
          && this.$refs.circle.mapObject) {
        
          this.onCircleMarkerUpdated();
        }
      }
    },

    equalsLatLng (first, second) {
      let { lat: firstLat, lng: firstLng } = first || {};
      let { lat: secondLat, lng: secondLng } = second || {};

      if (
        !(firstLat && secondLat)
        && Array.isArray(first)
        && Array.isArray(second)
      ) {
        [
          firstLat,
          firstLng,
        ] = first;
        [
          secondLat,
          secondLng,
        ] = second;
      }

      return (
        firstLat
        && secondLat
        && firstLng
        && secondLng
        && firstLat.toString() === secondLat.toString()
        && firstLng.toString() === secondLng.toString()
      );
    },

    onCircleMarkerUpdated (event) {
      if (!this.useMapFilter) return;

      if (
        this.$refs.circle
        && this.$refs.circle.mapObject
        && this.$refs.circleMarker
        && this.$refs.circleMarker.mapObject
      ) {
        this.$refs.circleMarker.mapObject.setLatLng(
          this.$refs.circle.mapObject.getLatLng(),
        );
      }

      if (event && this.$refs.circle && this.$refs.circle.mapObject) {
        const { latlng: latLng, oldLatLng } = event;

        if (!this.equalsLatLng(latLng, oldLatLng)) {
          const radius = this.$refs.circle.mapObject.getRadius();

          this.circleRadius = radius || MAP_OPTIONS.DEFAULT_RADIUS;

          const { lat, lng } = this.$refs.circle.mapObject.getLatLng();

          if (typeof lat !== 'undefined' && typeof lng !== 'undefined') {
            this.circleCenter = [
              lat,
              lng,
            ];
          }
        }
      }
    },

    onFreeDrawChanged (polygons) {
      if (this.filterMode === 'area') {
        if (!this.useMapFilter) return;

        this.areaPolygon
          = polygons && polygons.length ? polygons[0].toGeoJSON() : null;
      }
    },

    navigateToProperty (item) {
      if (!this.property || item.addressId !== this.property.addressId) {
        this.gtmEvent = viewProductGTM(item);
      }

      this.fetchProperty({
        addressId: item.addressId,
        domain: this.$route.name,
      });
    },

    onClusterClick (event) {
      if (this.$refs.map && this.$refs.map.mapObject) {
        this.$refs.map.mapObject.setView(event.layer._latlng, 18);
      }
    },

    drawControl () {
      this.desenhoControl = true;
    },
  },
};

</script>

<style>

.leaflet-tooltip {
  opacity: 1 !important;
  background: initial !important;
  color: transparent !important;
  padding: initial !important;
  box-shadow: none !important;
  border-radius: 0 !important;
  border: none !important;
}

.leaflet-tooltip-top:before,
.leaflet-tooltip-bottom:before,
.leaflet-tooltip-left:before,
.leaflet-tooltip-right:before {
  position: absolute;
  pointer-events: none;
  border: 6px solid transparent !important;
  background: transparent !important;
  content: '';
}

.leaflet-tooltip-bottom {
  margin-bottom: 10px !important;
}

</style>

<style scoped lang="scss">

.draw-success-tooltip {
  position: fixed;
  z-index: 404;
  background-color: #FFFFFF;
  border: 1px solid #DDDDDD;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.14);
  padding: 22px;
  border-radius: 10px;

  &::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -15px;
    border-width: 15px;
    border-style: solid;
    border-color: #FFF transparent transparent transparent;
  }
}

.map-pin-legend {
  background: #FFF;
  border-radius: 5px;
  padding: 8px;
  display: flex;
  justify-content: space-between;
  column-gap: 8px;
  width: 380px;
  position: absolute;
  bottom: 36px;
  left: 50%;
  transform: translateX(-50%);
  color: #303A56;
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  font-size: 14px;
  z-index: 4;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.14);
  transition: bottom .25s;

  > div {
    display: flex;
    column-gap: 4px;
    white-space: nowrap;

    > span {
      padding-bottom: 1px;
    }

    > img {
      display: inline-block;
    }
  }
}
.pin-tooltip {
  opacity: 1 !important;
  color: #FFF !important;
  font-size: 13px !important;
  font-weight: 700 !important;
  font-family: 'Lato', sans-serif !important;
  padding: 1px 5px 0 !important;
  box-shadow: none !important;
  border: 1px solid #FFF !important;
  border-radius: 2px !important;
  width: fit-content !important;
  margin: 0 auto !important;
  position: relative !important;
  top: 18px !important;

  &.is-blue {
    background-color: #6B8CEF !important;
  }

  &.is-green {
    background-color: #24C196 !important;
  }

  &.is-red {
    background-color: #EF6B6B !important;
  }

  &.is-yellow {
    background-color: #E3D450 !important;
  }

  &.is-darkblue {
    background-color: #334887 !important;
  }

  &.is-unknown {
    background-color: #A2A5AB !important;
  }
}
.leaflet-popup {
  width: calc(236px + 24px);
  .leaflet-popup-content {
    width: calc(236px + 24px) !important;
  }
}

.map-is-drawing {
  cursor: crosshair;
}

#map-wrapper {
  z-index: 3;
  width: 100%;
  height: calc(100% - 1px);
}

.map-has-active-item {
  :deep(.leaflet-marker-icon) {
    .active {
      z-index: 9999 !important;
    }

    .inactive {
      img {
        width: auto !important;
        height: 60px !important;
        .pin {
          height: 45px !important;
        }
      }
    }
  }
}

:deep(.leaflet-top.leaflet-left) {
  display: none !important;
}

:deep(.leaflet-bottom.leaflet-right) {
  height: 100%;
  display: flex;
  align-items: center;
  .leaflet-control-attribution {
    display: none !important;
  }
}

:deep(.leaflet-marker-icon) {
  > span {
    display: flex;
    height: 100%;

    img {
      margin: auto !important;
    }
  }
}

</style>
