import { render, staticRenderFns } from "./Buyer.vue?vue&type=template&id=6cc04d61"
import script from "./Buyer.vue?vue&type=script&lang=js"
export * from "./Buyer.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports