<template>
  <div class="w-full h-full">
    <FilterArea
      :hidden="showResults"
      class="h-full w-full"
    >
      <template #header>
        <Typography
          tag="h1"
          variant="header-black"
          color="brand-dark"
          align="center"
        >
          {{ NAVBAR_MENU_ITEMS.SELLER.label }}
        </Typography>
      </template>

      <template #filters>
        <AddressFilter
          :filter="addressFilter"
          @condoUpdate="onCondoUpdated"
        />
      </template>

      <template #additional-filters>
        <UnitFilters
          class="w-full"
          :condo="condo ? condo.info : null"
          :filter="unitFilter"
        />
      </template>

      <template #button-area>
        <ButtonBar
          :on-confirm="debouncedSearch"
          :on-clean="clearAllFilters"
          :disabled="!searchIsEnabled"
          :is-loading="isLoading"
          :has-data="hasData && showResults"
        />
      </template>
    </FilterArea>

    <div
      v-show="showResults"
      class="h-full flex flex-col"
    >
      <SearchResult
        slot="search-results"
        class="flex h-full"
        :condo-id="(condo && condo.info && condo.info.addressId) || 0"
        :search-results="result && Object.values(result).filter(item => !!item.addressLine1)"
        :unit-filter="unitFilter"
        :loading="loading"
        @reload-search="debouncedSearch()"
        @back-to-search="hideResults()"
      />

      <ButtonBarResult
        class="w-full"
        v-if="showResults"
        :on-return="hideResults"
      />
    </div>

    <GoogleTagManager :track-event="gtmEvent" />
  </div>
</template>

<script>

import debounce from 'lodash.debounce';
import { mapState, mapActions, mapMutations } from 'vuex';

import {
  NAVBAR_MENU_ITEMS,
  PROPERTY_TYPE,
  FILTER_MODE,
  CITY_CENTER,
} from '@/common/constants';

import Typography from '@/components/base/Typography';

import GoogleTagManager from '@/components/google/GoogleTagManager.vue';
import FilterArea from '@/components/sidebar/FilterArea.vue';
import ButtonBar from '@/components/sidebar/ButtonBar.vue';
import SearchResult from './SearchResult.vue';
import UnitFilters from './UnitFilters.vue';
import AddressFilter from './AddressFilter.vue';
import ButtonBarResult from '@/components/sidebar/ButtonBarResult.vue';
import searchMixin from '../../mixins/searchMixin';

export default {
  name: 'Seller',
  mixins: [searchMixin],
  components: {
    Typography,
    FilterArea,
    ButtonBar,
    ButtonBarResult,
    SearchResult,
    AddressFilter,
    UnitFilters,
    GoogleTagManager,
  },
  data () {
    return {
      debugArray: ['initiated'],
      PROPERTY_TYPE,
      addressFilter: {
        location: null,
      },
      unitFilter: {
        propertyType: null,
        areaRange: null,
        propertyAgeVar: 30,
        propertyAttributes: [],
        typologyName: null,
      },
      gtmEvent: null,
    };
  },
  computed: {
    ...mapState('core', ['expandedSidebar']),
    ...mapState('summary', ['summary']),
    ...mapState({
      filter (state, getters) {
        return getters[`${this.$route.name}/filter`]; 
      },
      result (state, getters) {
        return getters[`${this.$route.name}/result`]; 
      },
      error (state, getters) {
        return getters[`${this.$route.name}/error`]; 
      },
      isLoading (state, getters) {
        return getters[`${this.$route.name}/isLoading`]; 
      },
      originalResult (state, getters) {
        return getters[`${this.$route.name}/originalResult`]; 
      },
    }),
    hasData () {
      return !!(this.result && Object.values(this.result).length);
    },
    searchIsEnabled () {
      const condo = this.condo ? this.condo.info : null;

      if (!condo) return false;

      return this.unitFilter.propertyType
          && this.unitFilter.areaRange
          && this.unitFilter.suites
          && this.unitFilter.bedrooms
          && this.unitFilter.parkingLots;
    },
    loading () {
      return this.isLoading || this.searchLoading || this.summaryLoading;
    },
  },
  watch: {
    filter (value) {
      if (!value) this.hideResults();
    },
    condo (condo) {
      this.$eventBus.$emit('condoSelected', condo);
    }
  },
  methods: {
    ...mapActions('map', [
      'setCurrentCity',
      'resetMapZoomLevel',
    ]),
    ...mapActions('core', [
      'setUseMapFilter',
      'toggleExpandSidebar',
    ]),
    ...mapActions('summary', ['applySellerSummary']),
    ...mapActions('seller', [
      'sellerSearch',
      'clearSearch',
      'clearFilters',
    ]),
    ...mapMutations({
      setMapCenter: 'map/SET_MAP_CENTER',
      setFilter (commit, payload) {
        return commit(`${this.$route.name}/SET_FILTER`, payload); 
      },
      setFilterMode (commit, payload) {
        return commit(`${this.$route.name}/SET_FILTER_MODE`, payload); 
      },
    }),
    changeCurrentCity (key) {
      this.setCurrentCity(key);
      this.setMapCenter(CITY_CENTER[key]);
    },
    onCondoUpdated (condo) {
      this.condo = condo;
    },
    hideResults () {
      this.showResults = false;
      this.searchSummary = null;
      this.clearSearch();
      this.clearFilters();
      this.$router.replace({ path: this.$route.path, query: {} });
    },
    clearUnitFilters () {
      this.unitFilter = {
        propertyType: null,
        areaRange: null,
        propertyAgeVar: 30,
        propertyAttributes: [],
        cleared: true,
      };
    },
    clearAddressFilters () {
      this.condo = null;
      this.addressFilter = {
        location: null,
      };
    },
    clearAllFilters () {
      this.hideResults();
      this.clearFilters();
      this.clearUnitFilters();
      this.clearAddressFilters();
    },
    async getSearchSummary () {
      try {
        this.$eventBus.$emit('loading:summary', true);
        this.summaryLoading = true;
        await this.applySellerSummary();
        this.$eventBus.$emit('loading:summary', false);

        if (this.hasData) {
          this.gtmEvent = {
            event: 'view_result',
            has_result: +this.hasData,
          };
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.summaryLoading = false;
      }
    },
    async doSearch () {
      try {
        if (this.expandedSidebar) this.toggleExpandSidebar();
        
        this.gtmEvent = {
          event: 'select_content',
          content_type: 'vendedor',
          district: this.condo.location?.district,
          city: this.condo.location?.city,
          property_type: this.unitFilter.typologyName,
          area: this.unitFilter.areaRange[2],
          bedrooms: this.unitFilter.bedrooms,
          parking_lots: this.unitFilter.parkingLots,
          suits: this.unitFilter.suites,
          year_construction: this.unitFilter.propertyAgeVar,
        };

        this.searchLoading = true;

        await this.sellerSearch({
          address: this.condo,
          attributesFilter: {
            ...this.unitFilter,
          },
        });

        if (!this.error) {
          this.showResults = true;
          await this.getSearchSummary();
          await this.setUrlParams();
        }
      } catch (error) {
        console.error(error);
        this.showResults = false;
      } finally {
        this.searchLoading = false;
      }
    },
  },
};
</script>
