<template>
  <div class="main-filters mx-auto w-full bg-white ">
    <Typography
      variant="title-medium"
      color="highlight-default"
    >
      Buscar por
    </Typography>

    <div
      id="filter-mode"
      class="mt-2"
    >
      <ul>
        <v-tooltip top>
          <template #activator="{ on }">
            <li
              :class="[
                'p-1',
                'w-1/3',
                'text-center',
                filterMode === FILTER_MODE.RADIUS && 'active',
                hoverRadius && 'hover',
              ]"
              v-on="on"
              @mouseover="hoverRadius = true"
              @mouseleave="hoverRadius = false"
            >
              <a
                :tabindex="1"
                class="flex flex-col w-full"
                @click.stop="setFilterMode(FILTER_MODE.RADIUS)"
              >
                <div class="icon-wrapper">
                  <img 
                    class="svg-icon"
                    src="@/assets/images/location.svg"
                    v-if="filterMode === FILTER_MODE.RADIUS && !hoverRadius"
                  >
                  <img 
                    class="svg-icon"
                    src="@/assets/images/location-hover.svg"
                    v-if="hoverRadius"
                  >
                  <img 
                    class="svg-icon"
                    src="@/assets/images/location-disabled.svg"
                    v-if="filterMode !== FILTER_MODE.RADIUS && !hoverRadius"
                  >
                </div>
                <Typography
                  variant="label-small"
                  color="brand-dark"
                >
                  Localização
                  <span
                    v-if="filter && filter.radius"
                    class="mx-2 text-xs"
                  >
                    ({{ ((filter.radius * 2) / 1000).toFixed(2) }}Km)
                  </span>
                </Typography>
              </a>
            </li>
          </template>
          <span>Controle no mapa o raio da busca desejada.</span>
        </v-tooltip>
        <v-tooltip top>
          <template #activator="{ on }">
            <li
              :class="[
                'p-1',
                'w-1/3',
                'text-center',
                filterMode === FILTER_MODE.REGION && 'active',
                hoverRegion && 'hover',
              ]"
              v-on="on"
              @mouseover="hoverRegion = true"
              @mouseleave="hoverRegion = false"
            >
              <a
                :tabindex="1"
                class="flex flex-col w-full"
                @click.stop="setFilterMode(FILTER_MODE.REGION)"
              >
                <div class="icon-wrapper">
                  <img 
                    class="svg-icon"
                    src="@/assets/images/region.svg"
                    v-if="filterMode === FILTER_MODE.REGION && !hoverRegion"
                  >
                  <img 
                    class="svg-icon"
                    src="@/assets/images/region-hover.svg"
                    v-if="hoverRegion"
                  >
                  <img 
                    class="svg-icon"
                    src="@/assets/images/region-disabled.svg"
                    v-if="filterMode !== FILTER_MODE.REGION && !hoverRegion"
                  >
                </div>
                <Typography
                  variant="label-small"
                  color="brand-dark">
                    Região
                </Typography>
              </a>
            </li>
          </template>
          <span>Selecione a região desejada.</span>
        </v-tooltip>
        <v-tooltip top>
          <template #activator="{ on }">
            <li
              :class="[
                'p-1',
                'w-1/3',
                'text-center',
                filterMode === FILTER_MODE.AREA && 'active',
                hoverDraw && 'hover',
              ]"
              v-on="on"
              @mouseenter="hoverDraw = true"
              @mouseleave="hoverDraw = false"
            >
              <a
                :tabindex="1"
                class="flex flex-col w-full"
                @click.stop="setFilterMode(FILTER_MODE.AREA)"
              >
              <div class="icon-wrapper">
                  <img 
                    class="svg-icon"
                    src="@/assets/images/draw.svg"
                    v-if="filterMode === FILTER_MODE.AREA && !hoverDraw"
                  >
                  <img 
                    class="svg-icon"
                    src="@/assets/images/draw-hover.svg"
                    v-if="hoverDraw"
                  >
                  <img 
                    class="svg-icon"
                    src="@/assets/images/draw-disabled.svg"
                    v-if="filterMode !== FILTER_MODE.AREA && !hoverDraw"
                  >
                </div>
                <Typography
                  variant="label-small"
                  color="brand-dark"
                >
                  Desenhar
                </Typography>
              </a>
            </li>
          </template>
          <span>Desenhe no mapa a região desejada</span>
        </v-tooltip>
      </ul>
    </div>
    <div class="my-4">
      <GoogleMapAutocomplete
        v-if="filterMode === FILTER_MODE.RADIUS"
        v-model="gMapAutocomplete"
        :tabindex="3"
        @select="onAutoCompleteSelection"
      />
      <RegionAutocomplete
        v-else-if="filterMode === FILTER_MODE.REGION"
        v-model="regionModel"
        :tabindex="3"
        :current-city="currentCity"
        @region="onRegion"
      />
      <Autocomplete
        v-else-if="filterMode === FILTER_MODE.AREA"
        :tabindex="3"
        tabindex="1"
        placeholder="Digite um endereço ou o nome do condomínio"
        :disabled="true"
      />
    </div>
  </div>
</template>

<script>

import { mapState, mapMutations, mapGetters } from 'vuex';
import { FILTER_MODE, CITY_CENTER, MAP_OPTIONS } from '@/common/constants';

import PinIcon from '@/components/icons/PinIcon';
import MapIcon from '@/components/icons/MapIcon';
import DesignIcon from '@/components/icons/DesignIcon';
import GoogleMapAutocomplete from '@/components/autocomplete/GoogleMapAutocomplete';
import Autocomplete from '@/components/autocomplete/Autocomplete';
import RegionAutocomplete from '@/components/autocomplete/RegionAutocomplete';
import Typography from '@/components/base/Typography';

export default {
  components: {
    RegionAutocomplete,
    Autocomplete,
    GoogleMapAutocomplete,
    DesignIcon,
    MapIcon,
    PinIcon,
    Typography,
  },
  data: () => ({
    FILTER_MODE,
    CITY_CENTER,
    gMapAutocomplete: null,
    regionModel: null,
    hoverRadius: false,
    hoverRegion: false,
    hoverDraw: false,
  }),
  computed: {
    ...mapGetters('map', ['currentCity']),
    ...mapState({
      filter (state, getters) {
        return getters[`${this.$route.name}/filter`]; 
      },
      filterMode (state, getters) {
        return getters[`${this.$route.name}/filterMode`]; 
      },
      isLoading (state, getters) {
        return getters[`${this.$route.name}/isLoading`]; 
      },
      error (state, getters) {
        return getters[`${this.$route.name}/error`]; 
      },
    }),
    isMobile () {
      return this.$route.path.includes('mobile')
    }
  },
  watch: {
    filter: {
      deep: true,
      handler (filter) {
        if (!filter) {
          this.gMapAutocomplete = null;
          this.regionModel = null;
        }
      },
    },
  },

  methods: {
    ...mapMutations({
      setMapCenter: 'map/SET_MAP_CENTER',
      setAutocompleteData (commit, payload) {
        return commit(`${this.$route.name}/SET_AUTOCOMPLETE_DATA`, payload); 
      },
      setFilter (commit, payload) {
        return commit(`${this.$route.name}/SET_FILTER`, payload); 
      },
      setFilterMode (commit, payload) {
        return commit(`${this.$route.name}/SET_FILTER_MODE`, payload); 
      },
    }),
    onAutoCompleteSelection (value) {
      this.setAutocompleteData(value);

      if (!(value && value.geometry && value.geometry.location)) return;

      const { geometry: { location: { lat, lng } } } = value;
      const center = [
        lat(),
        lng(),
      ];

      this.setMapCenter(center);
      this.setFilter({
        position: center,
        radius: MAP_OPTIONS.DEFAULT_RADIUS,
      });
    },
    onRegion (value) {
      if (!value) return;

      const { center: point, coordinates } = value;

      this.$emit('update:region', value);
      this.setFilter({
        coordinates: [
          coordinates.map(([
            lat,
            lng,
          ]) => [
            lng,
            lat,
          ]),
        ],
        point,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.main-filters {
  border-bottom: none;
}

#filter-mode {
  ul {
    align-items: flex-start;
    align-self: stretch;
    display: flex ;
    justify-content: space-between;
    padding: 0;
  }

  li {
    .icon-wrapper {
      display: flex;
      margin: auto;
      border-radius: 9999px;
      margin-bottom: 8px;
      justify-content: center;
      border: 1px solid #e2e8f0;
      width: 52px;
      height: 52px;
      background-color: #F3F5FB;
    }

    &.active {
      .icon-wrapper{
        background-color: $colors-highlight-default;
      }
    }

    &.hover {
      .icon-wrapper{
        background-color: $colors-highlight-lighter;
      }
    }
  }
}
</style>
