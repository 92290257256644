<template>
  <div class="w-full h-full">
    <FilterArea
      :hidden="showResults || error"
      class="h-full w-full"
    >
      <LocationSelector
        slot="location-selector"
        class="w-full"
      /> 
      <LocationFilter
        slot="filters"
        class="w-full"
        @update:region="region = $event"
      />
      <PropertyFilters
        slot="additional-filters"
        v-model="attributesFilter"
        class="w-full"
      />
      <ButtonBar
        slot="button-area"
        v-if="!(hasData && showResults)"
        :on-clean="clearFormFilters"
        :on-confirm="debouncedSearch"
        :disabled="buttonDisabled"
        :is-loading="isLoading"
      />
    </FilterArea>

    <div
      v-show="showResults || error"
      class="h-full flex flex-col"
    >
      <SearchResult
        slot="search-results"
        class="flex h-full"
        :loading="loading"
        :search-summary="searchSummary"
        :search-results="result && Object.values(result).filter(item => !!item.addressLine1)"
        @reload-search="debouncedSearch()"
        @back-to-search="hideResults()"
        :filtersData="attributesFilter"
      />
      <ButtonBarResult
        class="absolute"
        v-if="showResults || error"
        :on-return="hideResults"
      />
    </div>
    <GoogleTagManager :track-event="gtmEvent" />
  </div>
</template>

<script>
import GoogleTagManager from '@/components/google/GoogleTagManager.vue';
import FilterArea from '@/components/sidebar/FilterArea.vue';
import ButtonBar from '@/components/sidebar/ButtonBar.vue';
import SearchResult from './shared/SearchResult.vue';
import PropertyFilters from '@/components/sidebar/PropertyFilters.vue';
import LocationFilter from '@/components/sidebar/LocationFilter.vue';
import LocationSelector from '@/components/sidebar/LocationSelector.vue';
import ButtonBarResult from '../components/sidebar/ButtonBarResult.vue';
import searchMixin from '../mixins/searchMixin';

export default {
  name: 'Buyer',

  mixins: [searchMixin],

  components: {
    FilterArea,
    ButtonBar,
    ButtonBarResult,
    SearchResult,
    LocationFilter,
    LocationSelector,
    PropertyFilters,
    GoogleTagManager,
  },
};
</script>
